import Vue from 'vue';
import VueRouter from 'vue-router';
import i18n from '../i18n';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Index',
    component: () => import('../views/Index.vue'),
  },
  {
    path: '/:locale',
    component: () => import('../views/MainPage.vue'),
    beforeEnter: (to, from, next) => {
      const { locale } = to.params;
      const supportedLocales = process.env.VUE_APP_I18N_SUPPORTED_LOCALES.split(
        ',',
      );

      if (!supportedLocales.includes(locale)) {
        return next(process.env.VUE_APP_I18N_LOCALE);
      }
      if (i18n.locale !== locale) {
        i18n.locale = locale;
        document.documentElement.lang = locale;
        document.title = i18n.t('title');
      }

      return next();
    },
    children: [
      {
        path: '',
        alias: 'home',
        name: 'Home',
        component: () => import('../views/Home.vue'),
      },
      {
        path: 'news',
        name: 'News',
        component: () => import('../views/News.vue'),
      },
      {
        path: 'about',
        name: 'About',
        component: () => import('../views/About.vue'),
      },
      {
        path: 'music',
        name: 'Music',
        component: () => import('../views/Music.vue'),
      },
      {
        path: 'store',
        name: 'Store',
        component: () => import('../views/Store.vue'),
      },
      {
        path: 'contact',
        name: 'Contact',
        component: () => import('../views/Contact.vue'),
      },
      {
        path: '*',
        name: 'NotFound',
        component: () => import('../views/ViewNotFound.vue'),
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
