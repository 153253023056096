import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    toDustApiBaseUrl: 'https://api.todust.com/api',
    emailApiBaseUrl: 'https://email-api.wndl.se',
  },
  mutations: {},
  actions: {},
  modules: {},
  getters: {
    toDustApiBaseUrl: (state) => state.toDustApiBaseUrl,
    emailApiBaseUrl: (state) => state.emailApiBaseUrl,
  },
});
